import { PasswordInput } from '@/components/blocks/PasswordInput';
import { useJSPStoreUrl } from '@/data/Content/JSPStoreUrl';
import { UserLogon, personMutatorKeyMatcher, useLogin } from '@/data/Content/Login';
import { useStoreName } from '@/data/Content/StoreName';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { useForm } from '@/utils/useForm';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { useSWRConfig } from 'swr';

const initLoginValues: UserLogon = {
	logonId: '',
	logonPassword: '',
	rememberMe: false,
};

export const SignedOutDropMenu: FC = () => {
	const { AccountPopper: AccountPopperNLS } = useLocalization('Header');
	const signInNLS = useLocalization('SignIn');
	const { createJSPStoreUrlFromViewName } = useJSPStoreUrl();
	const router = useNextRouter();
	const RouteLocal = useLocalization('Routes');
	const { loginSubmit } = useLogin();
	const { mutate } = useSWRConfig();
	const { isBtemptd } = useStoreName();

	const {
		handleInputChange,
		values: loginValues,
		formRef,
		error,
		handleSubmit,
		submitting,
	} = useForm(initLoginValues);

	const handleLogin = async (props: UserLogon) => {
		props?.logonId ? (props.logonId = props.logonId.toLowerCase()) : null;
		const resp = await loginSubmit(props);
		if ('userId' in resp ?? {}) {
			if (router.query.flow === 'checkout') {
				await router.push(RouteLocal.CheckOut.route.t());
			} /* else {
				await router.push('/');
			}*/
			mutate(personMutatorKeyMatcher(''), undefined);
		}
	};

	return (
		<Stack spacing={1} divider={<Divider />}>
			<Stack divider={<Divider color="#BFBFBF" />} sx={{ width: '250px' }}>
				<Stack>
					<Stack
						spacing={2}
						component="form"
						onSubmit={handleSubmit(handleLogin)}
						noValidate
						ref={formRef}
					>
						<TextField
							variant="outlined"
							margin="normal"
							required
							name="logonId"
							autoComplete="username"
							label={AccountPopperNLS.EmailAddress.t()}
							autoFocus
							value={loginValues.logonId}
							onChange={handleInputChange}
							inputProps={{
								maxLength: 100,
							}}
							error={error.logonId}
							sx={{
								fieldset: { borderRadius: '0 !important' },
								input: { padding: '6px !important', textTransform: 'lowerCase' },
								'.MuiFormLabel-asterisk': { display: 'none' },
								label: { marginBottom: 1 },
							}}
						/>
						<PasswordInput
							required
							name="logonPassword"
							label={signInNLS.Password.t()}
							value={loginValues.logonPassword}
							onChange={handleInputChange}
							error={error.logonPassword}
							inputProps={{
								maxLength: 100,
							}}
							sx={{
								marginTop: '4px !important',
								fieldset: { borderRadius: '0 !important' },
								input: { padding: '6px !important' },
								'.MuiFormLabel-asterisk': { display: 'none' },
								label: { marginBottom: 1 },
							}}
						/>
						<Stack alignItems="flex-start">
							<Button
								type="submit"
								variant="contained"
								disabled={submitting}
								data-testid="account-dropdown-button-sign-in-submit"
								id="account-dropdown-button-sign-in-submit"
								sx={{ minWidth: '117px' }}
							>
								{signInNLS.SignInButton.t()}
							</Button>
						</Stack>
					</Stack>
					<Typography
						component="a"
						href={createJSPStoreUrlFromViewName(
							'ResetPasswordGuestErrorView',
							'&state=forgetpassword'
						)}
						sx={{
							color: '#000',
							fontSize: '14px',
							textTransform: 'uppercase',
							textDecoration: 'underline',
							fontWeight: 500,
							marginTop: '10px',
							marginBottom: '20px',
						}}
					>
						{AccountPopperNLS.ForgotPassword.t()}
					</Typography>
				</Stack>
				<Stack>
					<Typography
						sx={{ marginTop: '20px', marginBottom: '10px', color: '#4D4D4D', fontSize: '13px' }}
					>
						{AccountPopperNLS.DontHaveAnAccount.t()}
					</Typography>
					<Stack alignItems="flex-start">
						<Button
							component="a"
							variant="contained"
							href={createJSPStoreUrlFromViewName(
								'UserRegistrationForm',
								'&myAcctMain=1&registerNew=Y'
							)}
							sx={{ minWidth: '117px', backgroundColor: isBtemptd ? '#6D4F6D' : '' }}
						>
							{AccountPopperNLS.Register.t()}
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};
