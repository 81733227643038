
import { HttpClient } from './http-client';
import { LoginAsUser } from './LoginAsUser';
const publicClient = new HttpClient({
	baseUrl: process.env.NODE_ENV === 'production' ? '/webapp/wcs/stores/servlet':'/api/webapp',
});
const privateClient = new HttpClient({
	baseUrl: (process.env.USE_MOCK === 'true' ? 'http://localhost:' + process.env.MOCK_HOST_PORT : process.env.TRANSACTION_ORIGIN as string) + '/webapp/wcs/stores/servlet',
});

export const legacyLoginAsUser = (pub: boolean) => new LoginAsUser(pub ? publicClient : privateClient);
