/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SignedInDropMenu } from '@/components/content/Header/parts/AccountDropMenu/SignedInDropMenu';
import { SignedOutDropMenu } from '@/components/content/Header/parts/AccountDropMenu/SignedOutDropMenu';
import { useLocalization } from '@/data/Localization';
import { useUser } from '@/data/User';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { FC, useEffect, useState } from 'react';

export const MobileAccountDialog: FC<{
	openAccountDialog: boolean;
	setOpenAccountDialog: (arg0: boolean) => void;
}> = ({ openAccountDialog, setOpenAccountDialog }) => {
	const CloseNLS = useLocalization('Close');
	const { AccountPopper: AccountPopperNLS } = useLocalization('Header');
	const { user } = useUser();
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

	// for CDN caching -- render this on client
	useEffect(() => {
		setIsLoggedIn(() => user?.isLoggedIn ?? false);
	}, [user]);

	return (
		<Dialog
			open={openAccountDialog}
			onClose={() => setOpenAccountDialog(false)}
			aria-label={AccountPopperNLS.AccountMenu.t()}
		>
			<IconButton
				aria-label={CloseNLS.t()}
				onClick={() => setOpenAccountDialog(false)}
				sx={{ position: 'absolute', right: 0, color: '#000', zIndex: 1 }}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>{isLoggedIn ? <SignedInDropMenu /> : <SignedOutDropMenu />}</DialogContent>
		</Dialog>
	);
};
