import { useGlobalSpinner } from '@/data/Content/GlobalSpinner';
import { useNotifications } from '@/data/Content/Notifications';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useSettings } from '@/data/Settings';
import { useUser } from '@/data/User';
import { TransactionErrorResponse } from '@/data/types/Basic';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { processError } from '@/data/utils/processError';
import cookieCutter from '@boiseitguru/cookie-cutter';
import { legacyLoginAsUser } from 'integration/generated/legacy';
import { useCallback } from 'react';

export const useRunAsUser = () => {
	const { settings } = useSettings();
	const router = useNextRouter();
	const { storeId } = getClientSideCommon(settings, router);
	const { notifyError } = useNotifications();
	const { user } = useUser();
	const { setIsLoading } = useGlobalSpinner();

	const deleteUserLogonIdCookie = () => {
		const userLogonIdCookie = cookieCutter.get('WC_LogonUserId_' + storeId);
		if (userLogonIdCookie !== null) {
			cookieCutter.set('WC_LogonUserId_' + storeId, '', {
				expires: new Date(0),
				path: '/',
				domain: '',
			});
		}
	};

	const deleteBuyerUserNameCookie = useCallback(() => {
		cookieCutter.set('WC_BuyOnBehalf_' + storeId, '', {
			expires: new Date(0),
			path: '/',
			domain: '',
		});
	}, [storeId]);

	const setDeleteCartCookie = useCallback(() => {
		cookieCutter.set('WC_DeleteCartCookie_' + storeId, 'true', { path: '/', domain: '' });
	}, [storeId]);

	const deleteOnBehalfRoleCookie = useCallback(() => {
		const deleteOnBhealfRoleCookieVal = cookieCutter.get('WC_OnBehalf_Role_' + storeId);

		if (deleteOnBhealfRoleCookieVal !== null) {
			cookieCutter.set('WC_OnBehalf_Role_' + storeId, '', {
				expires: new Date(0),
				path: '/',
				domain: '',
			});
		}
	}, [storeId]);

	const restoreCSRSessionAndRedirect = (url?: string) => {
		deleteUserLogonIdCookie();
		deleteBuyerUserNameCookie();
		clearUserSetInSession(url);
	};

	const getAuthTokenFromResponse = useCallback((response: string) => {
		const regex = /(?:"authToken"\s:\s)(\S*)/;
		const matches = response.match(regex);
		if (matches && matches?.length > 1) {
			return matches[1];
		} else {
			return null;
		}
	}, []);

	const clearUserSetInSession = useCallback(
		(url?: string) => {
			if (user?.userId && typeof user.userId === 'string') {
				const runAsUserId: string = user.userId;
				try {
					setIsLoading(true);
					legacyLoginAsUser(true)
						.csrfAuthToken({ storeId, requesttype: 'ajax' }, { format: 'text' })
						.then((response) => {
							if (response) {
								const authToken = getAuthTokenFromResponse(response);
								if (authToken) {
									legacyLoginAsUser(true)
										.ajaxRestoreOriginalUserSetInSession(
											{ storeId, requesttype: 'ajax', runAsUserId, authToken },
											{ format: 'text' }
										)
										.then(() => {
											setIsLoading(false);
											deleteBuyerUserNameCookie();
											setDeleteCartCookie();
											deleteOnBehalfRoleCookie();
											if (url) {
												window.location.href = url;
											} else {
												router.push('/');
											}
										});
								}
							}
						})
						.catch((e) => {
							setIsLoading(false);
							notifyError(processError(e as TransactionErrorResponse));
						});
				} catch (e) {
					setIsLoading(false);
					notifyError(processError(e as TransactionErrorResponse));
				}
			}
		},
		[
			user?.userId,
			setIsLoading,
			storeId,
			getAuthTokenFromResponse,
			deleteBuyerUserNameCookie,
			setDeleteCartCookie,
			deleteOnBehalfRoleCookie,
			router,
			notifyError,
		]
	);

	return {
		clearUserSetInSession,
		restoreCSRSessionAndRedirect,
	};
};
