/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const headerAccountDropMenuSX: SxProps = {
	borderRadius: 0,
	border: '1px solid #BFBFBF',
	maxWidth: 'none',
	boxShadow: 0,
	margin: '0 !important',
	backgroundColor: '#fff',
	background:
		'linear-gradient(to bottom, #FFFFFF 46%, #F5F5F5 100%) repeat scroll 0 0 hsla(0, 0%, 0%, 0)',
	padding: '15px 20px 20px 20px',
	color: '#252525',
	letterSpacing: '0.25px',
};

export const headerAccountDropMenuWelcomeSX: SxProps = {
	padding: '0 10px 5px 10px',
	fontSize: '13px',
	fontWeight: 700,
};

export const headerAccountDropMenuButtonLinkSX: SxProps = {
	color: '#006ecc',
	fontSize: '12px',
	lineHeight: '22px',
	textAlign: 'left',
	padding: '8px 10px',
	border: 0,
	background: 'none',
	cursor: 'pointer',
};
