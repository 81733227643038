import { useSwitchStore } from '@/data/Content/SwitchStore';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useSettings } from '@/data/Settings';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';

export const useJSPStoreUrl = () => {
	const router = useNextRouter();
	const { settings } = useSettings();
	const { wacoalAmericaUrl, wacoalCanadaUrl, btemptdUrl } = useSwitchStore();
	const { langId, storeId, defaultCatalogId: catalogId } = getClientSideCommon(settings, router);
	let currentDomain = wacoalAmericaUrl;

	if (storeId === '10852') {
		currentDomain = btemptdUrl;
	} else if (storeId === '10853') {
		currentDomain = wacoalCanadaUrl;
	}

	const createJSPStoreUrlFromViewName = (viewName: string, additionalParams?: string): string =>
		`/webapp/wcs/stores/servlet/${viewName}?storeId=${storeId}&catalogId=${catalogId}&langId=${langId}${
			additionalParams ? additionalParams : ''
		}`;

	const createAbsoluteJSPStoreUrl = (viewName: string, additionalParams?: string): string =>
		currentDomain +
		`/${viewName}?storeId=${storeId}&catalogId=${catalogId}&langId=${langId}${
			additionalParams ? additionalParams : ''
		}`;
	return { createJSPStoreUrlFromViewName, createAbsoluteJSPStoreUrl };
};
