/* eslint-disable complexity */
/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import {
	headerAccountDropMenuButtonLinkSX,
	headerAccountDropMenuWelcomeSX,
} from '@/components/content/Header/styles/account/dropMenu';
import { headerAccountMenuSX } from '@/components/content/Header/styles/account/menu';
import { useJSPStoreUrl } from '@/data/Content/JSPStoreUrl';
import { useLogout } from '@/data/Content/Logout';
import { useRunAsUser } from '@/data/Content/RunAsUser';
import { useLocalization } from '@/data/Localization';
import { useUser } from '@/data/User';
import { Button, Dialog, DialogContent, Divider, Link, Stack, Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';

export const SignedInDropMenu: FC = () => {
	const { AccountPopper: AccountPopperNLS } = useLocalization('Header');
	// const RouteLocal = useLocalization('Routes');
	const { handleLogout, settings } = useLogout();
	const { user } = useUser();
	const { createJSPStoreUrlFromViewName } = useJSPStoreUrl();
	const { clearUserSetInSession, restoreCSRSessionAndRedirect } = useRunAsUser();
	const [showCSRDialog, setShowCSRDialog] = useState(false);

	const { onBehalfOfGuest, onBehalfOfRegisteredUser } = useMemo(
		() => ({
			onBehalfOfGuest: user?.onBehalfOf && !user?.onBehalfOfUserName,
			onBehalfOfRegisteredUser: user?.onBehalfOf && user?.onBehalfOfUserName,
		}),
		[user?.onBehalfOf, user?.onBehalfOfUserName]
	);

	const WelcomeMessage: FC = () => {
		if (onBehalfOfRegisteredUser) {
			return (
				<Typography variant="h6" sx={headerAccountDropMenuWelcomeSX}>
					{AccountPopperNLS.CSRRegistered.t({
						userName: user?.onBehalfOfUserName ?? '',
						logonId: user?.onBehalfOfUserId ?? '',
					})}
				</Typography>
			);
		} else if (onBehalfOfGuest) {
			return (
				<Typography variant="h6" sx={headerAccountDropMenuWelcomeSX}>
					{AccountPopperNLS.CSRGuest.t()}
				</Typography>
			);
		} else {
			return (
				<Typography variant="h6" sx={headerAccountDropMenuWelcomeSX}>
					{AccountPopperNLS.Welcome.t({
						firstName: user?.firstName ?? '',
					})}
				</Typography>
			);
		}
	};

	return (
		<>
			<Stack spacing={1}>
				<Stack divider={<Divider color="#bfbfbf" />} sx={headerAccountMenuSX}>
					<WelcomeMessage />
					{user?.isCSR ? (
						user?.onBehalfOf ? (
							<Typography
								variant="body2"
								sx={headerAccountDropMenuButtonLinkSX}
								component="button"
								onClick={() => setShowCSRDialog(true)}
							>
								{AccountPopperNLS.CSRHomePage.t()}
							</Typography>
						) : (
							<Link
								sx={{ display: 'block', padding: '8px 10px' }}
								href={createJSPStoreUrlFromViewName('CustomerServiceLandingPageView')}
							>
								<Typography
									variant="body2"
									sx={{ color: '#006ecc', fontSize: '12px', lineHeight: '22px' }}
								>
									{AccountPopperNLS.CSRHomePage.t()}
								</Typography>
							</Link>
						)
					) : null}
					{/*
					{user?.isEmployee && !user?.onBehalfOf ? (
						<Link
							sx={{ display: 'block', padding: '8px 10px' }}
							href={createJSPStoreUrlFromViewName('QuickOrderView')}
						>
							<Typography
								variant="body2"
								sx={{ color: '#006ecc', fontSize: '12px', lineHeight: '22px' }}
							>
								{AccountPopperNLS.QuickOrder.t()}
							</Typography>
						</Link>
					) : null}
					*/}
					{onBehalfOfGuest ? (
						<Typography
							variant="body2"
							sx={headerAccountDropMenuButtonLinkSX}
							component="button"
							onClick={() =>
								restoreCSRSessionAndRedirect(
									createJSPStoreUrlFromViewName(
										'UserRegistrationForm',
										`&myAcctMain=1&registerNew=Y&new=Y&guestUserId=${user?.onBehalfOfUserId}`
									)
								)
							}
						>
							{AccountPopperNLS.CSRGuestRegister.t()}
						</Typography>
					) : null}
					{user?.onBehalfOf ? (
						<Typography
							variant="body2"
							sx={headerAccountDropMenuButtonLinkSX}
							component="button"
							onClick={() =>
								clearUserSetInSession(
									createJSPStoreUrlFromViewName('CustomerServiceLandingPageView')
								)
							}
						>
							{AccountPopperNLS.CSRSigninSelf.t()}
						</Typography>
					) : null}
					{!onBehalfOfGuest ? (
						<Link
							sx={{ display: 'block', padding: '8px 10px' }}
							href={createJSPStoreUrlFromViewName('AjaxLogonForm', '&myAcctMain=1')}
						>
							<Typography
								variant="body2"
								sx={{ color: '#006ecc', fontSize: '12px', lineHeight: '22px' }}
							>
								{AccountPopperNLS.MyAccount.t()}
							</Typography>
						</Link>
					) : null}
					{/* settings.csrSession refers to the OOB separate CSR app session.
				This is not our customization for supporting CSR users. */}
					{settings?.csrSession ? null : (
						<Stack
							divider={<Divider />}
							direction="row"
							alignItems="center"
							justifyContent="flex-start"
							sx={{ padding: '18px 30px 0 30px' }}
						>
							<Button
								component="a"
								variant="contained"
								href="/"
								onClick={handleLogout}
								sx={{ width: '100%', fontSize: '11px' }}
							>
								{AccountPopperNLS.SignOut.t()}
							</Button>
						</Stack>
					)}
				</Stack>
			</Stack>
			{user?.onBehalfOf ? (
				<Dialog open={showCSRDialog} onClose={() => setShowCSRDialog(false)}>
					<DialogContent>
						<Typography>
							{AccountPopperNLS.CSRDialogContent.t({
								name: onBehalfOfGuest ? 'Guest' : user?.onBehalfOfUserName ?? '',
							})}
						</Typography>
						<Stack direction="row" justifyContent="Flex-end" spacing={1} sx={{ marginTop: 1 }}>
							<Button
								variant="contained"
								onClick={() =>
									clearUserSetInSession(
										createJSPStoreUrlFromViewName('CustomerServiceLandingPageView')
									)
								}
							>
								{AccountPopperNLS.Yes.t()}
							</Button>
							<Button variant="contained" onClick={() => setShowCSRDialog(false)}>
								{AccountPopperNLS.No.t()}
							</Button>
						</Stack>
					</DialogContent>
				</Dialog>
			) : null}
		</>
	);
};
