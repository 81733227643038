import { useStoreName } from '@/data/Content/StoreName';
import { useSettings } from '@/data/Settings';
import { BTEMPTD_STOREID, WACOAL_CANADA_STOREID } from '@/data/constants/store';

export const useSwitchStore = () => {
	const { isWacoalAmerica, isWacoalCanada } = useStoreName();
	const { settings } = useSettings();

	const wacoalAmericaUrl =
		process.env.NODE_ENV === 'production' ? settings.customEnvVariables.wacoalAmericaURL : '/';
	const wacoalCanadaUrl =
		process.env.NODE_ENV === 'production'
			? settings.customEnvVariables.wacoalCanadaURL
			: `/?storeId=${WACOAL_CANADA_STOREID}`;
	const btemptdUrl =
		process.env.NODE_ENV === 'production'
			? settings.customEnvVariables.wacoalCanadaURL
			: `/?storeId=${BTEMPTD_STOREID}`;

	return { isWacoalAmerica, isWacoalCanada, wacoalAmericaUrl, wacoalCanadaUrl, btemptdUrl };
};
